<template>
  <div>
    <v-card class="rounded-lg mb-4">
      <v-card-text>
        <div class="text-subtitle-1 mb-3">
          <span class="font-weight-bold">{{ $t('statistics.ticket.author.title') }}</span>
        </div>
        <div class="d-flex justify-center align-center">
          <pie-chart
            :chart-data="charts.data"
            :options="charts.options"
            style="max-width: 250px"
            :width="240"
            :height="240"
          ></pie-chart>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PieChart from "./../commons/charts/pie.js";
import { DEFAULT_CHART_FONT, DEFAULT_TOOLTIP_CHART_FONT } from "./constants";
export default {
  props: {
    statistics: Array,
  },
  data: () => ({}),
  methods: {
    getAuthorColor(author) {
      const color = this.$utils.stringToColor(author);
      return this.$utils.lightenColor(color, 10);
    },
  },
  computed: {
    charts() {
      const data = {
        labels: [],
        datasets: [
          {
            label: this.$t("statistics.ticket.author.title"),
            data: [],
            backgroundColor: [],
          },
        ],
      };
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { labels: { ...DEFAULT_CHART_FONT } },
        tooltips: { ...DEFAULT_TOOLTIP_CHART_FONT }
      };
      const maps = {};
      for (const statistic of this.statistics) {
        const author = statistic.author;
        if (!author) continue;
        const total = parseInt(statistic.total || "0");
        if (!Object.prototype.hasOwnProperty.call(maps, author)) {
          data.labels.push(author);
          data.datasets[0].data.push(total);
          const color = this.getAuthorColor(author);
          const index = data.labels.length - 1;
          data.datasets[0].backgroundColor.push(color);
          maps[author] = index;
        } else {
          data.datasets[0].data[maps[author]] += total;
        }
      }
      return {
        data: data,
        options: options,
      };
    },
  },
  components: {
    PieChart,
  },
};
</script>