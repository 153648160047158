<template>
  <v-dialog v-model="dialogValue" max-width="500">
    <v-card>
      <v-card-title>
        <span class="text-h6">{{ $t("tickets.filter.title") }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <forms-filter
          ref="form"
          :statuses="progressionStatuses"
          :schema="schema"
          :sla-items="slaItems"
          :channels="channelsItems"
        ></forms-filter>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn text>
          <span class="font-weight-medium" @click="reset()">{{
            $t("btn.reset")
          }}</span>
        </v-btn>
        <v-btn color="primary" text>
          <span class="font-weight-bold" @click="filter()">{{
            $t("btn.filter")
          }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FormsFilter from "./../forms/filter/index.vue";
export default {
  props: {
    dialog: Boolean,
    progressionStatuses: Array,
    slaItems: Array,
    schema: Array,
  },
  data: () => ({}),
  methods: {
    filter() {
      this.$emit("filter", this.$refs.form.getFilters());
      this.dialogValue = false;
    },
    reset() {
      this.$emit("filter", this.$refs.form.resetFilters());
      this.dialogValue = false;
    },
  },
  computed: {
    ...mapGetters({ channelsItems: "channel/channelItems" }),
    dialogValue: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        return this.$emit("update:dialog", value);
      },
    },
  },
  components: {
    FormsFilter,
  },
};
</script>