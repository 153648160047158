<template>
  <div>
    <ticket></ticket>
  </div>
</template>

<script>
import Ticket from './../../components/statistics/ticket.vue';

export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t("statistics.ticket.title")
    };
  },
  components: {
    Ticket
  }
}
</script>