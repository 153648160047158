<template>
  <div class="mx-auto ticket-statistic">
    <v-card class="rounded-lg mb-4">
      <v-card-text class="py-0">
        <v-row wrap>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.serviceId"
              :placeholder="$i18n.t('tickets.fields.serviceId.placeholder')"
              class="pt-0 font-weight-bold"
              hide-details
              :loading="loading.service"
              item-text="name"
              item-value="id"
              :items="items.services"
              @change="changeService"
              append-icon="mdi-domain"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.formsId"
              :placeholder="$i18n.t('tickets.fields.formsId.placeholder')"
              class="pt-0 font-weight-bold"
              hide-details
              :loading="loading.forms"
              item-text="name"
              item-value="id"
              :items="items.forms"
              @change="changeForms"
              append-icon="mdi-form-select"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-if="!form.formsId">
      <v-card class="py-0 mb-4 rounded-lg">
        <v-card-text
          ><div
            class="d-flex justify-center align-center fill-height flex-column"
          >
            <span class="text-subtitle-1 grey--text text--darken-2">
              {{ $i18n.t("tickets.help.title") }}
            </span>
          </div></v-card-text
        >
      </v-card>
    </div>
    <div v-else>
      <statistic-date-picker
        ref="datepicker"
        :loading="loading.statistic"
        @changed="dateChanged"
        class="mb-4"
      >
        <div :class="{ 'mb-2': $vuetify.breakpoint.lgAndDown }">
          <v-btn
            @click="exportTickets()"
            :loading="loading.export"
            :disabled="loading.statistic"
            class="ml-2"
          >
            <v-icon small left>mdi-file-excel-box-outline</v-icon>
            {{ $t("btn.export") }}
          </v-btn>
        </div>
        <div :class="{ 'mb-2': $vuetify.breakpoint.lgAndDown }">
          <v-btn
            @click="displayFilter()"
            :disabled="loading.statistic"
            class="ml-2"
          >
            <v-icon small left>mdi-filter-variant</v-icon>
            {{ $t("btn.filter") }}
          </v-btn>
        </div>
        <div :class="{ 'mb-2': $vuetify.breakpoint.lgAndDown }">
          <v-btn
            @click="getStatistics()"
            :disabled="loading.statistic"
            class="ml-2"
          >
            <v-icon small left>mdi-refresh</v-icon>
            {{ $t("btn.refresh") }}
          </v-btn>
        </div>
      </statistic-date-picker>
      <div v-if="loading.statistic">
        <v-card>
          <v-card-text>
            <div class="text-center mt-5">
              <v-progress-circular
                indeterminate
                color="secondary"
                width="4"
                size="60"
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <div v-if="!hasStatistic">
          <v-card>
            <v-card-text>
              <div
                class="
                  d-flex
                  justify-center
                  align-center
                  fill-height
                  flex-column
                "
              >
                <span class="text-subtitle-1 grey--text text--darken-2">
                  {{ $i18n.t("statistics.noData") }}
                </span>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div v-else>
          <ticket-chart
            :statistics="statistics"
            :schema="schema"
            :progression="progression"
            :sla="sla"
          ></ticket-chart>
        </div>
      </div>
    </div>
    <ticket-filter
      :sla-items="slaItems"
      :schema="schema"
      :progression-statuses="progressionStatuses"
      :dialog.sync="dialog.filter"
      @filter="filter"
    ></ticket-filter>
  </div>
</template>

<script>
import TicketFilter from "./ticket-filter";
import TicketChart from "./ticket-chart";
import StatisticDatePicker from "./datepicker";
import { mapActions } from "vuex";
export default {
  created() {
    this.initServiceItems();
  },
  data: () => ({
    dates: [],
    items: {
      services: [],
      forms: [],
    },
    forms: {},
    statistics: [],
    loading: {
      service: false,
      forms: false,
      export: false,
      statistic: false,
    },
    form: {
      serviceId: null,
      formsId: null,
    },
    identifier: +new Date(),
    dialog: {
      filter: false,
    },
    filters: {},
  }),
  methods: {
    ...mapActions({ request: "request" }),
    async initServiceItems() {
      this.loading.service = true;
      try {
        const response = await this.request({ url: "services.items" });
        this.items.services = response.data;
      } catch (error) {
        // empty
      }
      this.loading.service = false;
    },
    async changeService(value) {
      this.items.forms = [];
      this.form.formsId = null;
      if (!value) return;

      this.loading.formsId = true;
      try {
        const response = await this.request({
          url: `forms.items?filter=service.id||$eq||${value}`,
        });
        this.items.forms = response.data;
      } catch (error) {
        // empty
      }
      this.loading.formsId = false;
    },
    async changeForms(value) {
      this.forms = {};
      this.statistics = [];
      if (!value) return;
      this.loading.forms = true;
      this.loading.statistic = true;
      try {
        const forms = (
          await this.request({
            url: `forms.get?formsId=${value}`,
            messages: { 403: true, 404: this.$i18n.t("forms.errors.404") },
          })
        ).data;
        this.forms = forms;

        this.$nextTick(() => {
          this.dates = this.$refs.datepicker.getDates();
          // get statistic
          this.getStatistics();
        });
      } catch (error) {
        // empty
        this.loading.statistic = false;
      }
      this.loading.forms = false;
    },
    async exportTickets() {
      this.loading.export = true;
      try {
        const params = {
          formsId: this.form.formsId,
          startDate: this.dates[0] + " 00:00:00",
          endDate: this.dates[1] + " 23:59:59",
        };
        const response = await this.request({
          url: "tickets.exports",
          params: params,
          messages: {
            404: this.$t("forms.errors.404"),
            403: true,
            500: true,
            422: this.$t("error_codes.500"),
          },
        });
        const source = response.data.source;
        const baseUrl = window.location.origin;
        const link = document.createElement("a");
        link.href = `${baseUrl}/${source}`;
        link.click();
      } catch (error) {
        // empty
      }
      this.loading.export = false;
    },
    displayFilter() {
      this.dialog.filter = true;
    },
    async getStatistics() {
      const queryFilter = this.$utils.getTicketFilterQuery(this.filters);

      this.loading.statistic = true;
      this.statistics = [];
      try {
        const params = {
          formsId: this.form.formsId,
          groups: [
            "progress",
            "sla",
            "author",
            "resolved",
            "violated",
            "violationFirstResponse",
            "violationResolution",
          ],
          startDate: this.dates[0] + " 00:00:00",
          endDate: this.dates[1] + " 23:59:59",
          period: "day",
        };
        const response = await this.request({
          url: `tickets.statistics?${queryFilter}`,
          params: params,
          messages: {
            422: this.$t('error_codes.500'),
            500: true,
            403: true
          }
        });
        this.statistics = response.data;
      } catch (error) {
        // empty
      }
      this.loading.statistic = false;
    },
    dateChanged(dates) {
      this.dates = dates;
      this.getStatistics();
    },
    filter(filters) {
      this.filters = filters;
      this.getStatistics();
    },
  },
  components: {
    StatisticDatePicker,
    TicketChart,
    TicketFilter,
  },
  computed: {
    hasStatistic() {
      return this.statistics.length ? true : false;
    },
    schema() {
      return this.forms?.data?.schema || [];
    },
    progression() {
      return this.forms?.data?.progress || {};
    },
    progressionStatuses() {
      return this.forms?.data?.progress?.states || [];
    },
    sla() {
      return this.forms?.data?.sla || {}
    },
    slaItems() {
      const slaItems = [];
      const targets = this.forms?.data?.sla?.targets || [];
      for (const target of targets) {
        slaItems.push({ text: target.title, value: target.value });
      }
      return slaItems;
    },
  },
};
</script>