<template>
  <div>
    <div v-if="hasProgression" class="mb-6">
      <ticket-chart-progression
        :statistics="statistics"
        :progression="progression"
      ></ticket-chart-progression>
    </div>
    <div class="mb-6">
      <ticket-chart-closed :statistics="statistics"></ticket-chart-closed>
    </div>
    <div>
      <ticket-chart-author :statistics="statistics"></ticket-chart-author>
    </div>
  </div>
</template>

<script>
import TicketChartClosed from "./ticket-chart-closed.vue";
import TicketChartAuthor from "./ticket-chart-author.vue";
import TicketChartProgression from "./ticket-chart-progression.vue";
export default {
  props: {
    statistics: Array,
    schema: Array,
    progression: Object,
    sla: Object
  },
  data: () => ({}),
  computed: {
    hasProgression() {
      return this.progression?._id ? true : false;
    },
    hasSla() {
      return this.sla?._id ? true : false
    }
  },
  components: {
    TicketChartClosed,
    TicketChartProgression,
    TicketChartAuthor
  },
};
</script>